import React, { useEffect, useState } from 'react'
import { IoArrowBackCircleOutline, } from "react-icons/io5";
import EgoldLogo from "../../images/LoginEgold.png"
import {
    Spinner,
    Input,
    InputGroup,
    InputGroupText,
} from "reactstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import "./login.css"
import { logIn, getoptional2fastat, checkStatus, verifyPW } from '../../api/apiService';
import { useNavigate } from "react-router-dom";
import { FaRegPaste } from "react-icons/fa6";
export default function Login() {
    const [pweye, setPweye] = useState(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [Otp, setOTP] = useState("");
    const [showOTP, setshowOTP] = useState(false);

    const [errorMSG, seterrorMSG] = useState({ status: null, message: "" });
    const [loginLoader, setLoginLoader] = useState(false);
    const [OTPLoader, setOTPLoader] = useState(false);

    let history = useNavigate();


    async function LoginUser(e) {
        e.preventDefault();
        localStorage.setItem("isAuthenticated", false);
        seterrorMSG({ status: false });

        if (email.length && password.length > 0) {
            setLoginLoader(true);
            const verifyData = { email: email, password: password };
            localStorage.setItem("email", email);
            verifyPW(verifyData).then((res) => {
                if (res.data.status == true) {
                    checkStatus(email)
                        .then((json) => {
                            console.log("RESPONSE", json);
                            setLoginLoader(false);
                            localStorage.setItem("email", email);
                            if (json.data.status == true) {
                                localStorage.setItem("twoFaStatus", true);
                                // history("/otpauth", { state: password });
                                setshowOTP(true)
                            } else {
                                localStorage.setItem("twoFaStatus", false);
                                const data = {
                                    email: email,
                                    password: password,
                                    toptp: "string",
                                };
                                logIn(data)
                                    .then((json) => {
                                        console.log(" LOGIN", json);
                                        if (json.data.code == 200) {
                                            localStorage.setItem(
                                                "accessToken",
                                                json.data.access_token
                                            );
                                            localStorage.setItem(
                                                "refreshToken",
                                                json.data.refresh_token
                                            );
                                            localStorage.setItem("accessTime", Date.now());
                                            localStorage.setItem("isAuthenticated", true);
                                            history("/enableauth");
                                            setEmail("");
                                            setPassword("");
                                        } else {
                                            seterrorMSG({ status: true, message: "错误的邮箱帐号或密码" });
                                            localStorage.setItem("isAuthenticated", false);
                                            setEmail("");
                                            setPassword("");
                                            console.log("ERROR");
                                        }
                                    })
                                    .catch((error) => {
                                        seterrorMSG({ status: true, message: json.data.detail });
                                        console.log("LOGIN ERROR", error);
                                        setEmail("");
                                        setPassword("");
                                    });
                            }
                        })
                        .catch((error) => {
                            // seterrorMSG({ status: true, message: json.data.detail });
                            console.error("ERROR HERE", error);
                        });
                } else {
                    if (res.data.detail[0].msg) {


                        seterrorMSG({
                            status: true,
                            message: String(res.data.detail[0].msg).toUpperCase(),
                        });

                    } else {
                        if (res.data.detail == "Please verify your email address") {
                            history("/verifymail");
                        } else {
                            seterrorMSG({ status: true, message: res.data.detail });
                        }
                    }
                    setEmail("");
                    setPassword("");
                    setLoginLoader(false);
                }
            });
        } else {
            setEmail("");
            setPassword("");
            seterrorMSG({ status: true, message: "请输入凭据" });
        }
    }

    const checkAuth = async (otp) => {
        setOTP(otp);
        if (otp.length === 6) {
            setOTPLoader(true);
            seterrorMSG({ status: false });
            const data = {
                email: email,
                password: password,
                toptp: otp,
            };
            logIn(data).then((json) => {
                console.log("OTP LOGIN", json);
                setOTPLoader(false);
                if (json.data.code == 200) {
                    localStorage.setItem("acct", json.data.wallet_address);
                    localStorage.setItem("isAuthenticated", true);
                    localStorage.setItem("accessToken", json.data.access_token);
                    localStorage.setItem("refreshToken", json.data.refresh_token);
                    localStorage.setItem("createdAt", json.data.created_at);
                    localStorage.setItem("accessTime", Date.now());
                    var headers = {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + String(json.data.access_token),
                        },
                    };
                    getoptional2fastat(headers).then((json) => {
                        console.log("we here-", json.data.status);
                        if (json.data) {
                            localStorage.setItem("isoptionaltfa", json.data.status);
                        } else {
                            console.log("Error while fetching 2fa status");
                        }
                    });
                    history("/trade");
                } else {
                    seterrorMSG({ status: true, message: json.data.detail });
                    localStorage.setItem("isAuthenticated", false);
                }
            });
        }
    };
    useEffect(() => {
        if (
            localStorage.getItem("isAuthenticated") === "true" &&
            localStorage.getItem("twoFaStatus") === "true"
        ) {
            history("/trade");
        }
    }, []);
    return (
        <main className='loginsection' >
            <div class="mainsection ">
                <div class="logintabsec">
                    <div
                        class="backbtnsec mb20"
                        style={{ cursor: "pointer", position: "relative" }}
                    >
                        <a
                            class="backbtn"
                            href='https://cn.egoldproject.com/'
                        >
                            <IoArrowBackCircleOutline
                                style={{ margin: "0px", fontSize: "28px" }}
                            />
                            <span class="backtext">返回</span>{" "}
                        </a>
                        <div class="backheadmed" style={{ marginLeft: "unset" }}>{"登录"}</div>
                        <div></div>
                    </div>
                    <div class="loginsec">
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10 }}>
                            <img src={EgoldLogo} />
                        </div>
                        {
                            showOTP ?
                                <div style={{ marginTop: 20, marginBottom: 30 }}>
                                    <div style={{ marginTop: 10, padding: 10 }}>
                                        <label for="downline_rank" class="form-label" style={{ color: "#2D3748", fontSize: 15 }}>
                                        一次性TP
                                        </label>
                                        <InputGroup>
                                            <Input
                                                name="otp"
                                                type={"text"}
                                                id="otp"
                                                value={Otp}
                                                onChange={(otp) => {
                                                    checkAuth(otp.target.value);
                                                }}
                                                style={{
                                                    borderTop: "0.489247px solid rgb(222, 226, 230)",
                                                }}
                                                className='passwordbox'

                                            />

                                            <InputGroupText
                                                className={"inputicon"}
                                                onClick={async () => {
                                                    checkAuth(await navigator.clipboard.readText());
                                                }}
                                            >
                                                {OTPLoader ? <Spinner color="black" size={18} /> : <FaRegPaste color='black' size={22} />}

                                            </InputGroupText>
                                        </InputGroup>
                                    </div>

                                </div> :

                                <div>
                                    <form>
                                        <div style={{ marginTop: 30, padding: 10 }}>
                                            <label for="downline_rank" class="form-label" style={{ color: "#2D3748", fontSize: 15 }}>
                                            邮件地址
                                            </label>
                                            <InputGroup>
                                                <Input
                                                    className="withdrawinput"
                                                    type="text"
                                                    name="amount"
                                                    id="email"
                                                    style={{
                                                        backgroundColor: "#fff",
                                                        border: " 0.489247px solid #dee2e6",
                                                        borderTop: "0.489247px solid rgb(222, 226, 230)",
                                                        color: "#000",
                                                        fontSize: "13px",
                                                        padding: "13px 12px",
                                                        borderRadius: "8px",
                                                    }}
                                                    value={email}
                                                    onChange={(email) => {
                                                        setEmail(email.target.value);
                                                    }}
                                                />
                                            </InputGroup>
                                        </div>

                                        <div style={{ marginTop: 10, padding: 10 }}>
                                            <label for="downline_rank" class="form-label" style={{ color: "#2D3748", fontSize: 15 }}>
                                            密码
                                            </label>
                                            <InputGroup>
                                                <Input
                                                    className={errorMSG.status ? "passwordboxerror" : "passwordbox"}
                                                    name="amount"
                                                    type={pweye ? "password" : "text"}
                                                    id="pw"
                                                    value={password}
                                                    onChange={(password) => {
                                                        setPassword(password.target.value);
                                                    }}
                                                />
                                                <InputGroupText
                                                    className={errorMSG.status ? "inputiconerror" : "inputicon"}
                                                    onClick={() => {
                                                        setPweye(!pweye)
                                                    }}
                                                >
                                                    {pweye ? <AiFillEyeInvisible color='#4F6B75' size={24} /> : <AiFillEye color='#4F6B75' size={24} />}
                                                </InputGroupText>
                                            </InputGroup>
                                            {errorMSG.status ? (
                                                <p className="errorbox">{errorMSG.message}</p>
                                            ) : null}
                                        </div>
                                        <div style={{ marginTop: 30, padding: 10 }}>
                                            <button className='btnlogin' type="submit" onClick={(e) => {
                                                LoginUser(e);
                                            }}
                                                style={{ color: "black" }}
                                            >
                                                {loginLoader ? <Spinner size="sm" /> : "登录"}
                                            </button>
                                        </div>
                                    </form>
                                    <div style={{ display: "grid", justifyContent: "center", alignContent: "center", marginTop: 20, marginBottom: 20 }}>
                                        <span style={{
                                            marginBottom: 20,
                                            color: "#253237",
                                            fontWeight: "500",
                                            fontSize: 18,
                                            textAlign: "center",
                                            cursor: "pointer"
                                        }}
                                            onClick={() => {
                                                history("/forgotpw")
                                            }}
                                        >
                                            忘记密码？
                                        </span>
                                        <span style={
                                            {
                                                color: "#232325", fontWeight: '400', fontSize: 14, display: "flex", gap: 8, textAlign: "center"
                                            }
                                        }>
                                            还没有账户? <p style={{
                                                color: "#FFC727",
                                                fontWeight: "700",
                                                fontSize: 16,
                                                cursor: "pointer"

                                            }}
                                                onClick={() => {
                                                    history("/register")
                                                }}
                                            >
                                                注册
                                            </p>
                                        </span>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </main>
    )
}
