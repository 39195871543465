import React from "react";
import { useNavigate } from "react-router-dom";

export default function StakePillsMenu(props) {
  let navigate = useNavigate();
  console.log("prsopss", props)
  return (
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          id="pills-live-tab" data-bs-toggle="pill" data-bs-target="#pills-live" type="button" role="tab" aria-controls="pills-live"
          class={
            props.activeval == "inactive"
              ? "nav-link typetabbtn brltb active "
              : "nav-link typetabbtn brltb"
          }
          aria-selected={props.activeval == "inactive" ? "true" : "false"}
          onClick={() => {
            navigate("/mine/inactive");
          }}
        >
          {/* Inactive ({localStorage.getItem("inactivecount") ? localStorage.getItem("inactivecount") : "0"}) */}
          {console.log("inactive count", props.inactivecount)}
          不活跃 ({props.inactivecount !== "..." ? (props.inactivecount - (localStorage.getItem("junkcount") >= 0 ? localStorage.getItem("junkcount") : 0)) : "..."})
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          id="pills-completed-tab" data-bs-toggle="pill" data-bs-target="#pills-completed" type="button" role="tab" aria-controls="pills-completed"
          class={
            props.activeval == "active"
              ? "nav-link typetabbtn  active "
              : "nav-link typetabbtn "
          }
          aria-selected={props.activeval == "active" ? "true" : "false"}
          onClick={() => {
            navigate("/mine/active");
          }}
        >
          活跃 ({props.activecount !== "..." ? props.activecount : "..."})
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          id="pills-upcoming-tab" data-bs-toggle="pill" data-bs-target="#pills-upcoming" type="button" role="tab" aria-controls="pills-upcoming"
          class={
            props.activeval == "junk"
              ? "nav-link typetabbtn brrtb active "
              : "nav-link typetabbtn brrtb"
          }
          aria-selected={props.activeval == "junk" ? "true" : "false"}
          onClick={() => {
            navigate("/mine/junk");
          }}
        >
          废旧物品 ({localStorage.getItem("junkcount") ? localStorage.getItem("junkcount") : "0"})
        </button>
      </li>
    </ul>
  );
}
