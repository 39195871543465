import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaShoppingCart, FaUserCog, FaUserPlus } from "react-icons/fa";
import { IoStatsChart } from "react-icons/io5";
import plus from "../../../images/plusss.svg";
import { FaRegPaste } from "react-icons/fa6";
import {
  Input,
  InputGroup,
  InputGroupText,
  Spinner
} from "reactstrap";
import dataVal from "../../../data/Abis.json";
import ErrorCard from "../../LoaderCard/ErrorCard";
import LoaderCard from "../../LoaderCard/LoaderCard";
import ConfirmCard from "../../LoaderCard/ConfirmCard";
import { invokeservice } from "../../../api/apiService";
export default function LPCollectWidget(props) {
  let navigate = useNavigate();
  const Web3 = require("web3");

  const BUSDFARM_ABI = dataVal.lpfarmabi;
  const BUSD_EGOLD_FARM_ADDRESS = process.env.REACT_APP_BUSD_EGOLD_FARM;
  const PANCAKE_FACTORY_ADDRESS = process.env.REACT_APP_PANCAKE_FACTORY_ADDR;
  const PANCAKE_FACTORY_ABI = dataVal.pancakeFactoryAbi
  const BUSD_ADDRESS = dataVal.busdtokencontract;
  const EGOLD_ADDRESS = dataVal.egoldtokencontract;
  const TOKEN_ABI = dataVal.busdtokenabi;

  const [depositedamnt, setdepositedamnt] = useState("0.00");
  const [farmingshare, setfarmingshare] = useState("0.00");
  const [farmedVal, setfarmedVal] = useState("0.00");
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });
  const [OTP, setOTP] = useState("");
  const [submitLoader, setsubmitLoader] = useState(false);


  useEffect(() => {

    if (localStorage.getItem("acct")) {
      console.log("active");
      fetchLQBalance();
      getfarmedInfo();
    } else {
      console.log("inactive");
      setdepositedamnt("0.00");
      setfarmingshare("0.00");
      setfarmedVal("0.00");
      getfarmedInfo();
    }
  }, []);



  const fetchLQBalance = async () => {
    if (localStorage.getItem("acct")) {
      const web3 = new Web3(process.env.REACT_APP_RPC);
      const pancakeFactorycontractInstance = await new web3.eth.Contract(
        PANCAKE_FACTORY_ABI,
        PANCAKE_FACTORY_ADDRESS
      );
      await pancakeFactorycontractInstance.methods
        .getPair(BUSD_ADDRESS, EGOLD_ADDRESS)
        .call({}, function (e, res) {
          const LQInstance = new web3.eth.Contract(TOKEN_ABI, res);
          LQInstance.methods
            .balanceOf(BUSD_EGOLD_FARM_ADDRESS)
            .call({}, function (e, resLQStake) {

              getLPTdepositblnce(
                parseFloat(web3.utils.fromWei(resLQStake)).toFixed(4)
              );
            });
        });
    }
  };


  //to not round values while showing balance
  function noround(val, x) {
    if (!val) return 0;
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  const getLPTdepositblnce = async (stakedval) => {
    if (localStorage.getItem("acct")) {
      const webb3 = new Web3(process.env.REACT_APP_RPC);
      const depositLPTInstance = await new webb3.eth.Contract(
        BUSDFARM_ABI,
        BUSD_EGOLD_FARM_ADDRESS
      );
      await depositLPTInstance.methods
        .deposited(0, localStorage.getItem("acct"))
        .call({}, function (e, resBalance) {


          setdepositedamnt(noround(webb3.utils.fromWei(resBalance), 4));
          var amnt =
            parseFloat(webb3.utils.fromWei(resBalance)).toFixed(4) /
            Number(stakedval);
          amnt = amnt * 100;
          console.log("SHARE", amnt);
          setfarmingshare(parseFloat(amnt).toFixed(6));
        });
    }
  };

  const getfarmedInfo = async () => {
    const webb3 = new Web3(process.env.REACT_APP_RPC);
    const farmedLPTInstance = await new webb3.eth.Contract(
      BUSDFARM_ABI,
      BUSD_EGOLD_FARM_ADDRESS
    );
    if (localStorage.getItem("acct")) {
      await farmedLPTInstance.methods
        .pending(0, localStorage.getItem("acct"))
        .call({}, function (e, resFarmed) {
          console.log("reesss", resFarmed)
          setfarmedVal(parseFloat(webb3.utils.fromWei(resFarmed)).toFixed(4));
        });
    }
  };

  const ClaimLQ = async () => {
    setprocessState({
      state: "...",
      data: null
    })
    if (depositedamnt > 0) {
      if (localStorage.getItem("isoptionaltfa") == "false" || OTP) {
        setprocessState({
          state: "processing",
          data: null
        })
        const token = localStorage.getItem("accessToken");
        const claimWithdraw = {
          job: {
            type: "withdrawLPyeild",
            params: {
              useragent: navigator.userAgent,
              amount: depositedamnt,
              totp: localStorage.getItem("isoptionaltfa") == "true" ? OTP : "",
            },
          },
        };
        var headers = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + String(token),
          },
        };
        invokeservice(claimWithdraw, headers).then((json) => {
          if (json.data.status != "failed") {
            fetchLQBalance();
            getfarmedInfo();
            setprocessState({
              state: "done",
              data: json.data.task_id
            });
          } else {
            setprocessState({
              state: "error",
              data: json.data.message
            });
          }
        });
      }
    } else {
      setprocessState({
        state: "error",
        data: "Insufficient balance"
      });
    }
  };


  function checkerror(err) {
    if (
      err.message ==
      "Please pass numbers as strings or BN objects to avoid precision errors." || err.message.includes("while converting number to string, invalid number value ")
    ) {
      setprocessState({
        state: "error",
        data: "Please provide a valid input",
      });
    } else if (JSON.stringify(err.message).includes("transaction underpriced"))
      setprocessState({
        state: "error",
        data: "Transaction was underpriced. Please try increasing the gas price",
      });
    else
      setprocessState({
        state: "error",
        data: JSON.stringify(err.message),
      });
  }
  return (
    <div class="detailCard mb20">
      <div class="bg-white secpadding brfull">
        <div class="d-flex justify-content-between mb20">
          <div>
            <p class="walletsecp">您的存款 (LPT)</p>
            <p class="walletsecp2" style={{ textAlign: "left" }}>{depositedamnt}</p>
          </div>
          <div>
            <p class="walletsecp">农耕分享</p>
            <p class="walletsecp2">{farmingshare}%</p>
          </div>
          <div>
            <p class="walletsecp">养殖（Egold）</p>
            <p class="walletsecp2">${farmedVal}</p>
          </div>
        </div>
        {
          localStorage.getItem("isoptionaltfa") == "true" ?
            <div style={{ marginTop: 20, marginBottom: 30 }}>
              <div style={{ marginTop: 10 }}>
                <label for="receiver_address" class="form-label">
                  请输入2FA代码 :
                </label>
                <InputGroup>
                  <Input
                    name="otp"
                    type={"text"}
                    id="otp"
                    value={OTP}
                    onChange={(otp) => {
                      setOTP(otp.target.value);
                    }}
                    style={{
                      borderTop: "0.489247px solid rgb(222, 226, 230)",
                    }}
                    className='passwordbox'
                  />
                  <InputGroupText
                    className={"inputicon"}
                    onClick={async () => {
                      setOTP(await navigator.clipboard.readText());
                    }}
                  >
                    <FaRegPaste color='black' size={22} />
                  </InputGroupText>
                </InputGroup>
              </div>

            </div> : null
        }
        <a class="btn-color-primary" style={{ cursor: "pointer" }} onClick={() => ClaimLQ()}>
        收集产量
        </a>
        {processState.state == "..." ? (
          ""
        ) : processState.state == "processing" ? (
          <LoaderCard />
        ) : processState.state == "done" ? (
          <ConfirmCard tx={processState.data} />
        ) : (
          <ErrorCard err={processState.data} />
        )}
      </div>

    </div>
  );
}
