import React, { useEffect, useState } from "react";
import "./css/wallet.css";
import BnbIcon from "../../images/currency/bnb.svg";
import { useNavigate } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import Token from "./Tokens.js"
// import MinerDetails from "../Manage/MinerDetails";
import InactiveMiners from "../Manage/InactiveMiners";
import dataVal from "../../data/Abis.json";
import { FaRegPaste } from "react-icons/fa6";
import LoaderCard from "../LoaderCard/LoaderCard";
import ConfirmCard from "../LoaderCard/ConfirmCard";
import ErrorCard from "../LoaderCard/ErrorCard";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import BackButton from "../Buttons/BackButton/BackButton";
import { invokeservice } from "../../api/apiService";
const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);
const EGOLD_ADDRESS = dataVal.egoldtokencontract;
const BUSD_ADDRESS = dataVal.busdtokencontract;
const CBK_ADDRESS = dataVal.cbktokencontract;
const EGOLDV2_ADDRESS = dataVal.egoldv2tokencontract;
const WBNB_ADDRESS = dataVal.wbnbtokencontract;
const WBNB_ABI = dataVal.wbnbAbi;
const BSCUSD_ADDRESS = "0x55d398326f99059fF775485246999027B3197955"

export default function Wallet() {
  const history = useNavigate();
  const [userbnbBalance, setUserbnbbalance] = useState("0");
  const [bnbPrice, setbnbPrice] = useState("0");
  const [modalV, setmodalV] = useState(false);
  const [conversionAmnt, setconversionAmnt] = useState("");
  const [totalBalance, settotalBalance] = useState(0);
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });
  const [OTP, setOTP] = useState("");


  const toggleModal = () => {
    setmodalV(!modalV);
  };

  useEffect(() => {
    getSummary();
  }, []);

  async function getSummary() {
    var xbal = await web3.eth.getBalance(localStorage.getItem("acct"));
    setUserbnbbalance(noround(web3.utils.fromWei(xbal, "ether"), 4));
    const url =
      "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd";
    fetch(url, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((json) => {
        console.log("BNB RESPONSE", json);
        setbnbPrice(json.binancecoin.usd);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  //to not round values while showing balance
  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }

  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      var x = noround(num, 3);
      return x; // if value < 1000, nothing to do
    }
  }

  async function convertwbnb() {
    // const webb3 = new Web3(connector.provider);
    if (localStorage.getItem("isoptionaltfa") == "false" || OTP) {

      if (conversionAmnt > userbnbBalance) {
        setprocessState({ state: "error", data: "Insufficient balance" });
      } else {
        setprocessState({ state: "processing", data: null });
        try {
          const token = localStorage.getItem("accessToken");
          var headers = {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + String(token),
            },
          };
          var convertdata = {
            job: {
              type: "ConvertBnbWbnb",
              params: {
                useragent: navigator.userAgent,
                amount: conversionAmnt,
                totp: localStorage.getItem("isoptionaltfa") == "true" ? OTP : "",
              },
            },
          };
          invokeservice(convertdata, headers).then((json) => {
            setOTP("");
            if (json.data.status != "failed") {
              setprocessState({ state: "done", data: json.data.task_id });
            } else {
              setOTP("");
              setprocessState({ state: "error", data: json.data.message });
            }
          });

        } catch (e) {
          console.log(e);
          setprocessState({ state: "error", data: JSON.stringify(e.message) });
        }
      }
    }
    else {
      setprocessState({ state: "error", data: "Incorrect 2Fa code" });
    }

  }

  const updateValue = (newValue) => {
    console.log("fn call!!!!", newValue);

    settotalBalance((totalBalance) => totalBalance + newValue);
  };

  return (
    <main>
      <div class="mainsection">
        <div class="tabsec secpadding lightgrey-bg brfull">
          <BackButton Title="钱包" ReRoute="trade" />
          <div class="sendreceivesec darksec-bg mb20">
            <p class="walletamtp">
              $
              {numFormatter(
                noround(bnbPrice * userbnbBalance + totalBalance, 3)
              )}
            </p>
            <div class="selectcurbtnsec">
              <a
                onClick={() => {
                  history("/send", { state: ["bnb"] });
                }}
                class="btn-outline-color-secondary sendbtn"
              >
                发送
              </a>
              <a
                onClick={() => {
                  history("/receive", { state: ["bnb"] });
                }}
                class="btn-outline-color-secondary receivebtn"
              >
                收到
              </a>
            </div>
          </div>
          <div class="wallettabsec lightgrey-bg brtlr">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link typetabbtn brltb active "
                  id="pills-token-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-token"
                  type="button"
                  role="tab"
                  aria-controls="pills-token"
                  aria-selected="true"
                >
                  代币
                </button>
              </li>

              <li class="nav-item" role="presentation">
                <button
                  class="nav-link typetabbtn  brrtb"
                  id="pills-nft-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-nft"
                  type="button"
                  role="tab"
                  aria-controls="pills-nft"
                  aria-selected="false"
                >
                  NFT
                </button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-token"
                role="tabpanel"
                aria-labelledby="pills-token-tab"
                tabindex="0"
              >
                <div
                  class="wallettokenrow"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history("/selecttoken", {
                      state: [
                        numFormatter(noround(userbnbBalance, 4)),
                        "bnb",
                        bnbPrice,
                      ],
                    });
                  }}
                >
                  <div class="selectcurrowcontent">
                    <div class="selectcurimgside">
                      <div class="curicon">
                        <img src={BnbIcon} class="img-fluid" />
                      </div>
                      <div class="curicondel">
                        <p
                          className="marginzero"
                          style={{
                            fontWeight: "500",
                            gap: 8,
                            display: "flex",
                            textAlign: "center",
                            fontSize: 16,
                          }}
                        >
                          BNB
                          <span class="badge rounded-pill text-bg-secondary selectcurpricep beptewn">
                            <p
                              className="marginzero"
                              style={{ textAlign: "center", fontSize: 10 }}
                            >
                              BEP20
                            </p>
                          </span>
                        </p>
                        <p class="selectcurpricep marginzero ">${bnbPrice}</p>
                      </div>
                    </div>
                    <div class="selectdetailside">
                      <p class="selectcuramt marginzero">{userbnbBalance}</p>
                      <p class="selectcurpricep text-end marginzero">
                        ${noround(bnbPrice * userbnbBalance, 5)}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history("/selecttoken", {
                      state: [WBNB_ADDRESS, "wbnb"],
                    });
                  }}
                >
                  <Token
                    data={{ type: "wbnb", addr: WBNB_ADDRESS }}
                    updateTotalValue={updateValue}
                  />
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history("/selecttoken", {
                      state: [BUSD_ADDRESS, "busd"],
                    });
                  }}
                >
                  <Token
                    data={{ type: "busd", addr: BUSD_ADDRESS }}
                    updateTotalValue={updateValue}
                  />
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history("/selecttoken", {
                      state: [EGOLD_ADDRESS, "egold", "1"],
                    });
                  }}
                >
                  <Token
                    data={{ type: "egold", version: "1", addr: EGOLD_ADDRESS }}
                    updateTotalValue={updateValue}
                  />
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history("/selecttoken", {
                      state: [EGOLDV2_ADDRESS, "egold", "2"],
                    });
                  }}
                >
                  <Token
                    data={{ type: "egold", version: "2", addr: EGOLDV2_ADDRESS }}
                    updateTotalValue={updateValue}
                  />
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history("/selecttoken", {
                      state: [CBK_ADDRESS, "cbk"],
                    });
                  }}
                >
                  <Token
                    data={{ type: "cbk", addr: CBK_ADDRESS }}
                    updateTotalValue={updateValue}
                  />
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history("/selecttoken", {
                      state: [BSCUSD_ADDRESS, "USDT"],
                    });
                  }}
                >
                  <Token
                    data={{ type: "USDT", addr: BSCUSD_ADDRESS }}
                    updateTotalValue={updateValue}
                  />
                </div>

                <div
                  class="btn-color-primary mb20"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  将 BNB 转换为 WBNB
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-nft"
                role="tabpanel"
                aria-labelledby="pills-nft-tab"
                tabindex="0"
              >
                <InactiveMiners />
              </div>
            </div>
          </div>
        </div>

      </div>
      <Modal
        isOpen={modalV}
        toggle={toggleModal}
        size="sm"
        backdrop="static"
        backdropClassName="connectmodalbg"
        className="modalswap"
      >
        <ModalHeader
          toggle={toggleModal}
          style={{
            color: "black",
            padding: "0.8rem",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              textTransform: "uppercase",
              color: "#000",
              // background: "#F5F5F5"
            }}
          >
            将 BNB 转换为 WBNB
          </div>
        </ModalHeader>
        <ModalBody
          style={{
            background: "#fff",
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            padding: "5px",
          }}
        >
          <div
            style={{
              borderRadius: 8,
              background: "#E0E7EA",
              marginTop: 10,
              margin: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 14,
              }}
            >
              <p
                style={{
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#4F6B75",
                }}
              >
                平衡
              </p>
              <p
                style={{
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#4F6B75",
                }}
              >
                {userbnbBalance} BNB ≈ ${noround(bnbPrice * userbnbBalance, 4)}
              </p>
            </div>
          </div>
          <div style={{ marginTop: 15, padding: "1rem" }}>
            <label
              for="conversionamnmt"
              style={{ fontSize: 14, fontWeight: "400", color: "#4F6B75" }}
              class="form-label"
            >
              兑换金额
            </label>
            <div style={{ marginBottom: 40 }}>
              <InputGroup>
                <Input
                  className="withdrawinput"
                  type="text"
                  name="amount"
                  value={conversionAmnt}
                  id="pw"
                  style={{
                    backgroundColor: "#fff",
                    border: " 0.489247px solid rgba(0, 0, 0, 0.3)",
                    borderRight: "0px",
                    color: "#000",
                    fontSize: "13px",
                    padding: "13px 12px",
                    borderRadius: "4px 0px 0px 4px",
                  }}
                  onChange={(amount) => {
                    setconversionAmnt(amount.target.value);
                  }}
                />
                <span className="bnbconversion">BNB</span>
                <InputGroupText
                  className="withdrawspan"
                  style={{
                    "text-transform": "uppercase",
                    "background-color": "#4F6B75",
                    border: "0.489247px solid rgb(137 137 137 / 30%)",
                    "font-weight": "500",
                    cursor: "pointer",
                    color: "#FFC727",
                    marginLeft: "0px",
                    fontSize: 13,
                  }}
                  onClick={(e) => setconversionAmnt(userbnbBalance)}
                >
                  最大使用
                </InputGroupText>
              </InputGroup>
              <p
                style={{
                  color: "#4F6B75",
                  fontSize: 12,
                  marginTop: 5,
                  right: 20,
                  position: "absolute",
                }}
              >
                {conversionAmnt} BNB ≈ ${noround(bnbPrice * conversionAmnt, 4)}
              </p>
            </div>
            {
              localStorage.getItem("isoptionaltfa") == "true" ?
                <div style={{ marginTop: 20, marginBottom: 30 }}>
                  <div style={{ marginTop: 10 }}>
                    <label for="receiver_address" class="form-label">
                      请输入2FA代码 :
                    </label>
                    <InputGroup>
                      <Input
                        name="otp"
                        type={"text"}
                        id="otp"
                        value={OTP}
                        onChange={(otp) => {
                          setOTP(otp.target.value);
                        }}
                        style={{
                          borderTop: "0.489247px solid rgb(222, 226, 230)",
                        }}
                        className='passwordbox'
                      />
                      <InputGroupText
                        className={"inputicon"}
                        onClick={async () => {
                          setOTP(await navigator.clipboard.readText());
                        }}
                      >
                        <FaRegPaste color='black' size={22} />
                      </InputGroupText>
                    </InputGroup>
                  </div>

                </div> : null
            }
            <a
              class="btn-color-primary mb20"
              onClick={() => {
                convertwbnb();
              }}
              style={{ cursor: "pointer" }}
            >
              转变
            </a>
            {processState.state == "..." ? (
              ""
            ) : processState.state == "processing" ? (
              <LoaderCard />
            ) : processState.state == "done" ? (
              <div className="convertsionbox">
                <ConfirmCard tx={processState.data} />
              </div>
            ) : (
              <div className="convertsionbox">
                <ErrorCard err={processState.data} />
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </main>
  );
}
