import React, { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import { Spinner } from "reactstrap";
import dataVal from "../../data/Abis.json";

const TotaleSupply = () => {
  const Web3 = require("web3");
  var FARMING_ABI = dataVal.farmAbi;
  var TOKEN_ABI = dataVal.tokenabi;
  const FARMING_ADDRESS = process.env.REACT_APP_FARMING_ADDR;
  const EGOLD_ADDRESS = process.env.REACT_APP_EGOLD_ADDR;

  const [data, setData] = useState([]);
  const [mintedtotal, setMintedTotal] = useState("...");
  const [unclaimedtotal, setUnclaimedTotal] = useState("...");
  const [totalburnt, setTotalBurnt] = useState("...");
  const [currentSupply, setcurrentSupply] = useState("...");

  const web3 = new Web3(process.env.REACT_APP_RPC);
  const farmInstance = new web3.eth.Contract(FARMING_ABI, FARMING_ADDRESS);
  const egtokenInstance = new web3.eth.Contract(TOKEN_ABI, EGOLD_ADDRESS);

  useEffect(() => {
    getSummary();
  }, []);

  const getSummary = async () => {
    const urlMiningStats = "https://egold-miner.herokuapp.com/miningStats";

    //getburnt
    fetch(urlMiningStats)
      .then(function (responseStats) {
        return responseStats.json();
      })
      .then(function (newStats) {
        setTotalBurnt(newStats.egoldSupply.burnt);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });

    await farmInstance.methods.paidOut().call({}, async function (e, res) {
      setMintedTotal(
        parseFloat(parseFloat(web3.utils.fromWei(res)).toFixed(2))
      );
    });
    await farmInstance.methods
      .totalPendingLP()
      .call({}, async function (e, res) {
        setUnclaimedTotal(
          parseFloat(parseFloat(web3.utils.fromWei(res)).toFixed(2))
        );
      });
    await egtokenInstance.methods
      .totalSupply()
      .call({}, async function (e, res) {
        setcurrentSupply(
          parseFloat(parseFloat(web3.utils.fromWei(res)).toFixed(2))
        );
      });
  };

  const CenteredMetric = ({ centerX, centerY }) => {
    return (
      <>
        <text
          x={centerX}
          y={centerY - 16}
          textAnchor="middle"
          dominantBaseline="central"
          fill="black"
          style={{
            fontSize: "18px",
          }}
        >
          最大供应量
        </text>
        <text
          x={centerX}
          y={centerY + 16}
          textAnchor="middle"
          dominantBaseline="central"
          fill="black"
          style={{
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
           21 M
        </text>
        </>
    );
  };

  useEffect(() => {
    if (currentSupply !== "..." && totalburnt !== "...") {
      setData([
        {
          id: "当前供应量",
          label: "当前供应量",
          value: noround(currentSupply, 1),
          color: "#4F6B75",
        },
        {
          id: "已燃烧",
          label: "已燃烧",
          value: noround(totalburnt, 1),
          color: "#FFC727",
        },
      ]);
    }
  }, [currentSupply, totalburnt]);

  //to not round values while showing balance
  function noround(val, x) {
    if (!val) return 0;
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  return (
    <>
      {currentSupply == "..." || totalburnt == "..." ? (
         <div style={{ padding: "20px", textAlign: "center" }}>
         <Spinner />
       </div>
      ) : (
        <div className="chartbx">
          <div className="chbxhdiv">
            <ResponsivePie
              data={data}
              margin={
                window.innerWidth < 500
                  ? { top: 20, right: 20, bottom: 0, left: 20 }
                  : { top: 40, right: 80, bottom: 40, left: 80 }
              }
              valueFormat=" >-,"
              startAngle={-10}
              sortByValue={false}
              activeOuterRadiusOffset={8}
              enableArcLinkLabels={window.innerWidth < 500 ? false : true}
              enableArcLabels={false}
              arcLinkLabel={(e) => `${e.id}\n(${e.value})`}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor="#ffffff"
              innerRadius={0.45}
              layers={[
                "arcs",
                "arcLabels",
                "arcLinkLabels",
                "legends",
                CenteredMetric,
              ]}
              colors={(slice) => slice.data.color}
            />
          </div>
          <div class="miningcapacity" id="miningCapacitySection">
            <div class="detailCard secpadding mb20">
              <div class="bardelshowp mb10">
              最大供应量 :{" "}
                21,000,000.00
              </div>
              <div class="bardelshowp mb10">
                <div class="primarybardot"></div>
                已燃烧EGOLD :{" "}
                {parseFloat(noround(totalburnt, 2)).toLocaleString()}
              </div>
              <div class="bardelshowp mb10">
                <div class="secondarybardot"></div>
                当前供应量 :{" "}
                {parseFloat(
                            noround(currentSupply, 2)
                          ).toLocaleString()}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default TotaleSupply;
