import React, { useEffect, useState, useRef } from 'react'
import EgoldLogo from "../../images/LoginEgold.png"
import BackButton from '../Buttons/BackButton/BackButton'
import {
    Input,
    InputGroup,
    InputGroupText,
} from "reactstrap";
import { FaRegPaste } from "react-icons/fa6";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import LoaderCard from "../LoaderCard/LoaderCard";
import ConfirmCard from "../LoaderCard/Changepw";
import ErrorCard from "../LoaderCard/ErrorCard";
import { checkStatus, resetPW } from "../../api/apiService";
import { useNavigate } from "react-router-dom";
import { BsFillCheckCircleFill } from "react-icons/bs";

export default function Changepw() {
    const [pweye, setPweye] = useState(true);
    const [errorMSG, seterrorMSG] = useState({ status: false, message: "" });
    const [password, setPassword] = useState("");
    const [newpassword, setnewPassword] = useState("");
    const [conpassword, setconPassword] = useState("");
    const [pwlen, setpwlen] = useState(false);
    const [pwupper, setpwupper] = useState(false);
    const [pwlower, setpwlower] = useState(false);
    const [pwnumber, setpwnum] = useState(false);
    const [pwspecial, setpwspecial] = useState(false);
    const [OTP, setOTP] = useState("");
    const [processState, setprocessState] = useState({
        state: "...",
        data: null,
    });


    function checkText(text) {
        setnewPassword(text);

        if (text.length >= 8) {
            setpwlen(true)
        } else {
            setpwlen(false)
        }
        if (!/[a-z]/.test(text)) {
            setpwlower(false)
        } else {
            setpwlower(true)
        }
        if (!/[A-Z]/.test(text)) {
            setpwupper(false)
        } else {
            setpwupper(true)
        }
        if (!/\d/.test(text)) {
            setpwnum(false)
        } else {
            setpwnum(true)
        }
        if (!/[^a-zA-Z0-9]/.test(text)) {
            setpwspecial(false)
        } else {
            setpwspecial(true)
        }
    }
    function changepassword(e) {
        e.preventDefault()
        setprocessState({ state: "...", data: null });
        if (pwlen && pwnumber && pwlower && pwupper && pwspecial) {
            const token = localStorage.getItem("accessToken");
            setprocessState({ state: "processing", data: null });
            const data = {
                password: password,
                newpassword: newpassword,
                newpwconfirm: conpassword,
                totp: OTP,
            };

            fetch("https://auth.egoldfarm.com/api/auth/changepw", {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + String(token),
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((json) => {
                    console.log("json jere", json)
                    if (json.status == "success") {
                        setprocessState({ state: "done", data: "" });
                        setPassword("");
                        setconPassword("");
                        setnewPassword("");
                        setOTP("");
                        setpwlen(false)
                        setpwlower(false)
                        setpwupper(false)
                        setpwnum(false)
                        setpwspecial(false)
                    } else if (json.status == "failed") {
                        setprocessState({ state: "error", data: json.message });
                    } else {
                        setprocessState({ state: "error", data: json.detail });
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            setprocessState({ state: "error", data: "密码不符合所有条件" });
        }
    }

    return (
        <main className='loginsection'>
            <div class="mainsection">
                <div class="tabsec" style={{ padding: 30, background: "#F5F5F5", borderRadius: 8 }}>
                    <BackButton Title="更改密码" ReRoute="" />
                    <div class="loginsec">
                        <form>
                            <div style={{ marginTop: 10, padding: 10 }}>
                                <label for="downline_rank" class="form-label" style={{ color: "#2D3748", fontSize: 15 }}>
                                当前密码
                                </label>
                                <InputGroup>
                                    <Input
                                        className={"passwordbox"}
                                        name="amount"
                                        type={pweye ? "password" : "text"}
                                        id="pw"
                                        value={password}
                                        onChange={(pw) => {
                                            setPassword(pw.target.value);
                                        }}
                                    />
                                </InputGroup>
                            </div>
                            <div style={{ marginTop: 10, padding: 10 }}>
                                <label for="downline_rank" class="form-label" style={{ color: "#2D3748", fontSize: 15 }}>
                                新密码
                                </label>
                                <InputGroup>
                                    <Input
                                        className={"passwordbox"}
                                        name="amount"
                                        type={pweye ? "password" : "text"}
                                        id="pw"
                                        value={newpassword}
                                        onChange={(password) => {
                                            checkText(password.target.value)

                                        }}
                                    />
                                    <InputGroupText
                                        className={"inputicon"}
                                        onClick={() => {
                                            setPweye(!pweye)
                                        }}
                                    >
                                        {pweye ? <AiFillEyeInvisible color='#4F6B75' size={24} /> : <AiFillEye color='#4F6B75' size={24} />}
                                    </InputGroupText>
                                </InputGroup>
                            </div>

                            <div style={{ marginTop: 10, padding: 10 }}>
                                <label for="downline_rank" class="form-label" style={{ color: "#2D3748", fontSize: 15 }}>
                                确认密码
                                </label>
                                <InputGroup>
                                    <Input
                                        className={errorMSG.status ? "passwordboxerror" : "passwordbox"}
                                        name="amount"
                                        type={"password"}
                                        id="pw"
                                        value={conpassword}
                                        onChange={(password) => {
                                            setconPassword(password.target.value);
                                            if (password.target.value !== newpassword) {
                                                seterrorMSG({ status: true, message: "* Password does not match" });
                                            } else {
                                                seterrorMSG({ status: false, message: "" });
                                            }
                                        }}
                                    />
                                </InputGroup>
                                {errorMSG.status ? (
                                    <p className="errorbox">{errorMSG.message}</p>
                                ) : null}
                            </div>

                            <div style={{ marginTop: 10, padding: 10 }}>
                                <label for="downline_rank" class="form-label" style={{ color: "#2D3748", fontSize: 15 }}>
                                    2FA
                                </label>
                                <InputGroup>
                                    <Input
                                        className="withdrawinput"
                                        name="amount"
                                        type={"text"}
                                        id="otp"
                                        value={OTP}
                                        onChange={(otp) => {
                                            setOTP(otp.target.value);
                                        }}
                                    />
                                    <InputGroupText
                                        className="pastespan"
                                        onClick={async () => {
                                            setOTP(await navigator.clipboard.readText())
                                        }}
                                    >
                                        <FaRegPaste color="#4F6B75" size={23} />
                                    </InputGroupText>
                                </InputGroup>
                            </div>
                            <div style={{ marginTop: 15, padding: 10 }}>
                                <button className='btnlogin' type="submit"
                                    onClick={(e) => {
                                        changepassword(e);
                                    }}
                                    style={{ color: "black" }}
                                >
                                    提交
                                </button>
                            </div>
                            {processState.state == "..." ? (
                                ""
                            ) : processState.state == "processing" ? (
                                <div style={{ margin: "20px" }}>
                                    <LoaderCard />
                                </div>
                            ) : processState.state == "done" ? (
                                <div style={{ margin: "20px" }}>
                                    <ConfirmCard />
                                </div>
                            ) : (
                                <div style={{ margin: "20px" }}>
                                    <ErrorCard err={processState.data} />
                                </div>
                            )}

                            <div style={{
                                marginTop: 40, border: " 1px solid #E2E8F0",
                                padding: "20px 20px 20px 20px",
                                textAlign: "left",
                                borderRadius: 4
                            }}>
                                <span>
                                    <p style={{ textAlign: "left", fontSize: 14, fontWeight: 500, color: "#232325" }}>
                                    您的密码必须包含 :
                                    </p>
                                    <div style={{ marginTop: 10 }}>
                                        <p style={{ display: "flex", gap: "10px", alignItems: "center", marginTop: 5 }}>
                                            <BsFillCheckCircleFill color={pwlen ? "#648795" : "#C1CFD5"} size={20} /> 至少 8 个字符
                                        </p>
                                        <p style={{ display: "flex", gap: "10px", alignItems: "center", marginTop: 5 }}>
                                            <BsFillCheckCircleFill color={pwupper ? "#648795" : "#C1CFD5"} size={20} />  至少一个大写字符
                                        </p>
                                        <p style={{ display: "flex", gap: "10px", alignItems: "center", marginTop: 5 }}>
                                            <BsFillCheckCircleFill color={pwlower ? "#648795" : "#C1CFD5"} size={20} />  至少一个小写字符
                                        </p>
                                        <p style={{ display: "flex", gap: "10px", alignItems: "center", marginTop: 5 }}>
                                            <BsFillCheckCircleFill color={pwnumber ? "#648795" : "#C1CFD5"} size={20} />  至少一个数字字符
                                        </p>
                                        <p style={{ display: "flex", gap: "10px", alignItems: "center", marginTop: 5 }}>
                                            <BsFillCheckCircleFill color={pwspecial ? "#648795" : "#C1CFD5"} size={20} />  至少一个特殊字符。 <br />eg : !,@,#,$,%,^,&,*
                                        </p>
                                    </div>

                                </span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    )
}
