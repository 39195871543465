import React, { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import { Spinner } from "reactstrap";
import dataVal from "../../data/Abis.json";

const TotaleSupply = () => {
  const Web3 = require("web3");
  var FARMING_ABI = dataVal.farmAbi;
  const FARMING_ADDRESS = process.env.REACT_APP_FARMING_ADDR;

  const [data, setData] = useState([]);
  const [mintedtotal, setMintedTotal] = useState("...");
  const [miningStats, setMiningStats] = useState({
    data: "...",
    loading: true,
  });
  const [salerburn, setSalerburn] = useState("...");

  const web3 = new Web3(process.env.REACT_APP_RPC);
  const farmInstance = new web3.eth.Contract(FARMING_ABI, FARMING_ADDRESS);

  useEffect(() => {
    getSummary();
  }, []);

  const getSummary = async () => {
    const urlMiningStats = "https://egold-miner.herokuapp.com/miningStats";
    const salerburn = "https://auth.egoldfarm.com/totalegburned";

    //fetch sales burn
    fetch(salerburn)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setSalerburn(newData.total_egold_burned);
      })
      .catch(function (error) {
        console.log(error);
      });

    //MiningStats
    fetch(urlMiningStats)
      .then(function (responseStats) {
        return responseStats.json();
      })
      .then(function (newStats) {
        setMiningStats({ data: newStats, loading: false });
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });

    await farmInstance.methods.paidOut().call({}, async function (e, res) {
      setMintedTotal(
        parseFloat(parseFloat(web3.utils.fromWei(res)).toFixed(2))
      );
    });
  };

  const CenteredMetric = ({ centerX, centerY }) => {
    return (
      <>
        <text
          x={centerX}
          y={centerY - 16}
          textAnchor="middle"
          dominantBaseline="central"
          fill="black"
          style={{
            fontSize: "18px",
          }}
        >
          EGOLD燃烧
        </text>
        <text
          x={centerX}
          y={centerY + 16}
          textAnchor="middle"
          dominantBaseline="central"
          fill="black"
          style={{
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          {numFormatter(miningStats.data.egoldSupply.burnt)}
        </text>
      </>
    );
  };

  useEffect(() => {
    if (
      mintedtotal !== "..." &&
      miningStats.data !== "..." &&
      salerburn !== "..."
    ) {
      setData([
        {
          id: "矿工销售燃烧",
          label: "矿工销售燃烧",
          value: noround(salerburn, 2),
          color: "#FFC727",
        },
        {
          id: "已燃烧的挖矿燃料",
          label: "已燃烧的挖矿燃料",
          value: noround(mintedtotal / 2, 2),
          color: "#4F6B75",
        },
        {
          id: "已燃烧的折旧",
          label: "已燃烧的折旧",
          value: noround(
            miningStats.data.egoldSupply.burnt - mintedtotal / 2 - salerburn,
            2
          ),
          color: "#FFE18C",
        },
      ]);
    }
  }, [mintedtotal, salerburn, miningStats]);

  //to not round values while showing balance
  function noround(val, x) {
    if (!val) return 0;
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return noround(num / 1000, 2) + " K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return noround(num / 1000000, 2) + " M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return num; // if value < 1000, nothing to do
    }
  }

  return (
    <>
      {mintedtotal == "..." ||
      salerburn == "..." ||
      miningStats.data == "..." ? (
        <div style={{ padding: "20px", textAlign: "center" }}>
          <Spinner />
        </div>
      ) : (
        <div className="chartbx">
          <div className="chbxhdiv">
            <ResponsivePie
              data={data}
              margin={
                window.innerWidth < 500
                  ? { top: 20, right: 20, bottom: 0, left: 20 }
                  : { top: 40, right: 80, bottom: 40, left: 80 }
              }
              valueFormat=" >-,"
              startAngle={0}
              sortByValue={false}
              activeOuterRadiusOffset={8}
              enableArcLinkLabels={window.innerWidth < 500 ? false : true}
              enableArcLabels={false}
              arcLinkLabel={(e) => `${e.id}\n(${e.value})`}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor="#ffffff"
              innerRadius={0.45}
              layers={[
                "arcs",
                "arcLabels",
                "arcLinkLabels",
                "legends",
                CenteredMetric,
              ]}
              colors={(slice) => slice.data.color}
            />
          </div>
          <div class="miningcapacity" id="miningCapacitySection">
            <div class="detailCard secpadding mb20">
              <div class="bardelshowp mb10">
                EGOLD总燃烧 :{" "}
                {parseFloat(
                  noround(miningStats.data.egoldSupply.burnt, 2)
                ).toLocaleString()}
              </div>
              <div class="bardelshowp mb10">
                <div class="secondarybardot"></div>已燃烧的挖矿燃料 :{" "}
                {parseFloat(noround(mintedtotal / 2, 2)).toLocaleString()}
              </div>
              <div class="bardelshowp mb10">
                <div class="otherbardot"></div>
                已燃烧的折旧 :{" "}
                {parseFloat(
                  noround(
                    miningStats.data.egoldSupply.burnt -
                      mintedtotal / 2 -
                      salerburn,
                    2
                  )
                ).toLocaleString()}
              </div>
              <div class="bardelshowp mb10">
                <div class="primarybardot"></div>
                矿工销售燃烧 :{" "}
                {parseFloat(noround(salerburn, 2)).toLocaleString()}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default TotaleSupply;
