import React, { useEffect, useState } from "react";
import "./css/send.css";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import dataVal from "../../data/Abis.json";
import LoaderCard from "../LoaderCard/LoaderCard";
import ConfirmCard from "../LoaderCard/ConfirmCard";
import ErrorCard from "../LoaderCard/ErrorCard";
import BackButton from "../Buttons/BackButton/BackButton";
import { invokeservice } from "../../api/apiService";
import { FaRegPaste } from "react-icons/fa6";
import { Input, InputGroup, InputGroupText } from "reactstrap";

const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);
const EGOLD_ADDRESS = dataVal.egoldtokencontract;
const EGOLDV2_ADDRESS = dataVal.egoldv2tokencontract;
const BUSD_ADDRESS = dataVal.busdtokencontract;
const CBK_ADDRESS = dataVal.cbktokencontract;
const WBNB_ADDRESS = dataVal.wbnbtokencontract;
const TOKEN_ABI = dataVal.tokenabi;
const BSCUSD_ADDRESS = "0x55d398326f99059fF775485246999027B3197955";
export default function Send() {
  const location = useLocation();
  const [sendToken, setsendToken] = useState("bnb");
  const [sendValue, setsendValue] = useState("");
  const [receiverAddr, setreceiverAddr] = useState("");
  const [tokenBalance, settokenbalance] = useState("0");
  const [tokenPrice, settokenPrice] = useState("0");
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });
  const [OTP, setOTP] = useState("");

  const tokens = [BUSD_ADDRESS, EGOLD_ADDRESS, WBNB_ADDRESS, CBK_ADDRESS];
  console.log("locastionstate", location);
  useEffect(() => {
    setsendToken(location.state[0]);
    if (location.state[0] == "bnb") {
      getBnbBalance();
    } else {
      console.log("LOCC-", location);
      if (location.state[0] == "egold") {
        if (location.state[1] == "1") getTokenBalances("egoldv1");
        else getTokenBalances("egoldv2");
      } else getTokenBalances(location.state[0]);
    }
  }, []);

  async function getBnbBalance() {
    var xbal = await web3.eth.getBalance(localStorage.getItem("acct"));
    settokenbalance(noround(web3.utils.fromWei(xbal, "ether"), 3));
    const url =
      "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd";
    fetch(url, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((json) => {
        console.log("BNB RESPONSE", json);
        settokenPrice(json.binancecoin.usd);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function getTokenBalances(addr) {
    setsendToken(addr);
    if (addr == "bnb") {
      getBnbBalance();
    } else {
      var tokenAddr = "";
      if (addr == "egoldv1") {
        tokenAddr = EGOLD_ADDRESS;
      } else if (addr == "egoldv2") {
        tokenAddr = EGOLDV2_ADDRESS;
      } else if (addr == "busd") {
        tokenAddr = BUSD_ADDRESS;
      } else if (addr == "wbnb") {
        tokenAddr = WBNB_ADDRESS;
      } else if (addr == "cbk") {
        tokenAddr = CBK_ADDRESS;
      } else if (addr == "USDT") {
        tokenAddr = BSCUSD_ADDRESS;
      }
      const tokencontractInstance = new web3.eth.Contract(TOKEN_ABI, tokenAddr);
      await tokencontractInstance.methods
        .balanceOf(localStorage.getItem("acct"))
        .call()
        .then((value) =>
          settokenbalance(noround(web3.utils.fromWei(value, "ether"), 3))
        )
        .catch((error) => console.error(error));
      if (addr == "cbk") {
        settokenPrice(noround(1, 5));
      } else {
        const urlprice =
          "https://egold-marketdata.herokuapp.com/v1/summary/getTokenPrices/" +
          tokenAddr;
        fetch(urlprice)
          .then(function (response) {
            return response.json();
          })
          .then(function (prices) {
            var info = {};
            if ("price" in prices) {
              settokenPrice(noround(prices.price, 5));
            }
          });
      }
    }
  }

  //to not round values while showing balance
  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }
  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  async function transferToken() {
    try {
      setprocessState({ state: "processing", data: null });
      if (sendValue == "" && receiverAddr == "") {
        setprocessState({
          state: "error",
          data: "Receiver address and amount not provided",
        });
      } else if (receiverAddr == "") {
        setprocessState({
          state: "error",
          data: "No receiver address provided",
        });
      } else if (sendValue == "") {
        setprocessState({
          state: "error",
          data: "No amount provided",
        });
      } else {
        if (!web3.utils.isAddress(receiverAddr)) {
          setprocessState({
            state: "error",
            data: "Invalid Referral Address",
          });
        } else if (parseFloat(sendValue) > parseFloat(tokenBalance)) {
          setprocessState({
            state: "error",
            data: "Insufficient balance",
          });
        } else {
          const token = localStorage.getItem("accessToken");

          var headers = {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + String(token),
            },
          };
          var tokensend = "";
          if (location.state[0] == "egoldv1" && location.state[1] == "1") {
            tokensend = EGOLD_ADDRESS;
          } else if (location.state[0] == "egold" && location.state[1] == "2") {
            tokensend = EGOLDV2_ADDRESS;
          } else if (location.state[0] == "busd") {
            tokensend = BUSD_ADDRESS;
          } else if (location.state[0] == "wbnb") {
            tokensend = WBNB_ADDRESS;
          } else if (location.state[0] == "cbk") {
            tokensend = CBK_ADDRESS;
          } else if (location.state[0] == "USDT") {
            tokensend = BSCUSD_ADDRESS;
          } else if (sendToken == "egoldv2") {
            tokensend = EGOLDV2_ADDRESS;
          } else if (sendToken == "egoldv1") {
            tokensend = EGOLD_ADDRESS;
          }

          if (sendToken == "bnb") {
            var withdrawdata = {
              job: {
                type: "WithdrawBNB",
                params: {
                  useragent: navigator.userAgent,
                  value: sendValue,
                  to: receiverAddr,
                  totp: OTP,
                },
              },
            };
          } else {
            var withdrawdata = {
              job: {
                type: "WithdrawTokenByaddress",
                params: {
                  useragent: navigator.userAgent,
                  value: sendValue,
                  to: receiverAddr,
                  token: tokensend,
                  tktype: sendToken,
                  totp: OTP,
                },
              },
            };
          }
          invokeservice(withdrawdata, headers).then((json) => {
            if (json.data.status != "failed") {
              setprocessState({ state: "done", data: json.data.task_id });
              getTokenBalances(location.state[0]);
            } else {
              setprocessState({
                state: "error",
                data: json.data.message,
              });
            }
          });
        }
      }
    } catch (e) {
      console.log(e);
      setprocessState({ state: "error", data: JSON.stringify(e.message) });
    }
  }
  return (
    <div>
      <main>
        <div class="mainsection">
          <div class="tabsec secpadding lightgrey-bg brfull">
            <BackButton Title="发送" ReRoute="wallet" />

            <select
              value={sendToken}
              class="form-select darksec-bg text-white mb20"
              aria-label="Default select example"
              onChange={(e) => {
                getTokenBalances(e.target.value);
              }}
            >
              <option value="bnb" defaultChecked>
                BNB - BEP20
              </option>
              <option value="egoldv1">EGOLD (V1)</option>
              <option value="egoldv2">EGOLD (V2)</option>
              <option value="busd">BUSD</option>
              <option value="wbnb">WBNB</option>
              <option value="cbk">CBK</option>
              <option value="USDT">USDT</option>
            </select>

            <div class="mb-3">
              <label for="receiver_address" class="form-label">
                收件人地址
              </label>
              <InputGroup>
                <Input
                  className="withdrawinput"
                  type="text"
                  name="receiver_address"
                  value={receiverAddr}
                  id="receiver_address"
                  style={{
                    backgroundColor: "#fff",
                    borderRight: "0px",
                    color: "#000",
                    fontSize: "13px",
                    padding: "13px 12px",
                    borderRadius: "8px 0px 0px 8px",
                    border: "1px solid #E2E8F0",
                    borderRightColor: "#fff",
                  }}
                  onChange={(e) => {
                    setreceiverAddr(e.target.value);
                  }}
                />

                <InputGroupText
                  className="pastespan"
                  onClick={async () => {
                    setreceiverAddr(await navigator.clipboard.readText());
                  }}
                >
                  <FaRegPaste color="#4F6B75" size={23} />
                </InputGroupText>
              </InputGroup>
            </div>
            <div class="mb-3">
              <label for="bnb_amount" class="form-label">
                {String(sendToken).toUpperCase()} 数量
              </label>
              <InputGroup>
                <Input
                  className="withdrawinput"
                  type="text"
                  name="amount"
                  value={sendValue}
                  id="amnt"
                  style={{
                    backgroundColor: "#fff",
                    borderRight: "0px",
                    color: "#000",
                    fontSize: "13px",
                    padding: "13px 12px",
                    borderRadius: "8px 0px 0px 8px",
                    border: "1px solid #E2E8F0",
                    borderRightColor: "#fff",
                  }}
                  onChange={(e) => {
                    setsendValue(e.target.value);
                  }}
                />
                <span
                  style={{
                    padding: 10,
                    background: "#fff",
                    border: "1px solid #E2E8F0",
                    color: "#253237",
                  }}
                >
                  {String(sendToken).toUpperCase()}
                </span>
                <InputGroupText
                  className="maxpan"
                  onClick={() => {
                    setsendValue(tokenBalance);
                  }}
                >
                  最大使用量
                </InputGroupText>
              </InputGroup>

              <p
                class="text-end"
                style={{ marginTop: 8, color: "#4F6B75", fontSize: "14px" }}
              >
                平衡: {tokenBalance} {String(sendToken).toUpperCase()}
              </p>
            </div>
            {/* <p class="inputdetailp mb15">
              Network Fee <span class="float-end">... BNB ≈ $...</span>
            </p> */}
            <p class="inputdetailp mb20">
              最大总数
              <span class="float-end">
                {sendValue == "" ? "0.00" : sendValue} ≈ $
                {sendValue == ""
                  ? "0.00"
                  : noround(parseFloat(sendValue) * parseFloat(tokenPrice), 4)}
              </span>
            </p>

            <div style={{ marginTop: 20, marginBottom: 30 }}>
              <div style={{ marginTop: 10 }}>
                <label for="receiver_address" class="form-label">
                  请输入2FA代码 :
                </label>
                <InputGroup>
                  <Input
                    name="otp"
                    type={"text"}
                    id="otp"
                    value={OTP}
                    onChange={(otp) => {
                      setOTP(otp.target.value);
                    }}
                    style={{
                      borderTop: "0.489247px solid rgb(222, 226, 230)",
                    }}
                    className="passwordbox"
                  />
                  <InputGroupText
                    className={"inputicon"}
                    onClick={async () => {
                      setOTP(await navigator.clipboard.readText());
                    }}
                  >
                    <FaRegPaste color="black" size={22} />
                  </InputGroupText>
                </InputGroup>
              </div>
            </div>
            <a
              class="btn-color-primary"
              style={{ textDecoration: "none", cursor: "pointer" }}
              onClick={() => {
                transferToken();
              }}
            >
              发送
            </a>
            <div style={{ marginTop: 20 }}>
              {processState.state == "..." ? (
                ""
              ) : processState.state == "processing" ? (
                <LoaderCard bg="dark" />
              ) : processState.state == "done" ? (
                <ConfirmCard tx={processState.data} bg="dark" />
              ) : (
                <ErrorCard err={processState.data} bg="dark" />
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
