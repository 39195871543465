import React, { useEffect, useState } from "react";
import BackButton from "../Buttons/BackButton/BackButton";
import { Input, InputGroup, InputGroupText } from "reactstrap";
import EgoldLogo from "../../images/LoginEgold.png";
import "./login.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { confirmresetpw } from "../../api/apiService";
import LoaderCard from "../LoaderCard/LoaderCard";
import ConfirmCard from "../LoaderCard/ConfirmCard";
import ErrorCard from "../LoaderCard/ErrorCard";
import { FaRegPaste } from "react-icons/fa6";

export default function ResetPassword() {
  const [newpassword, setnewpassword] = useState("");
  const [confirmpassword, setCNPassword] = useState("");
  const [OTP, setOTP] = useState("");
  const [errorMSG, seterrorMSG] = useState({ status: null, message: "" });
  const [errorMSG1, seterror1MSG] = useState({ status: null, message: "" });
  const [pweye, setPweye] = useState(true);
  const [verifypw, setverifyPw] = useState(false);
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });

  let params = useParams();
  let emailtoken = params.query;

  function ResetConfirmPassword(e) {
    e.preventDefault();
    seterrorMSG({ status: false });
    setprocessState({ state: "processing", data: null });
    if (
      newpassword.length > 0 &&
      confirmpassword.length > 0 &&
      OTP.length == 6
    ) {
      const data = {
        token: emailtoken,
        totp: OTP,
        newpassword: newpassword,
        newpwconfirm: confirmpassword,
      };
      var headers = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      confirmresetpw(data, headers)
        .then((json) => {
          if (json.data.status && json.data.status == "success") {
            setprocessState({ state: "done", data: "Success" });
          } else {
            setprocessState({
              state: "error",
              data: json.data.message,
            });
          }
        })
        .catch((error) => {
          setprocessState({
            state: "error",
            data: error.toString(),
          });
        });
    } else {
      setprocessState({
        state: "error",
        data: "请输入详细信息",
      });
    }
  }
  return (
    <main className="loginsection">
      <div class="mainsection">
        <div
          class="tabsec"
          style={{ padding: 30, background: "#F5F5F5", borderRadius: 8 }}
        >
          <BackButton Title="Reset password" ReRoute="" />
          <div class="loginsec">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 10,
              }}
            >
              <img src={EgoldLogo} />
            </div>
            <div style={{ marginTop: 30, padding: 10 }}>
              <label
                for="downline_rank"
                class="form-label"
                style={{ color: "#2D3748", fontSize: 15 }}
              >
                新密码
              </label>
              <InputGroup>
                <Input
                  className={errorMSG1.status ? "emailerror" : "emailbox"}
                  name="amount"
                  type={"password"}
                  id="pw"
                  value={newpassword}
                  onChange={(password) => {
                    setnewpassword(password.target.value);
                    password.target.value.length >= 8
                      ? seterror1MSG({ status: false, message: " " })
                      : seterror1MSG({
                          status: true,
                          message: "Passwords require 8 characters",
                        });
                  }}
                />
              </InputGroup>
              {errorMSG1.status ? (
                <p className="errorbox">{errorMSG1.message}</p>
              ) : null}
            </div>
            <div style={{ marginTop: 5, padding: 10 }}>
              <label
                for="downline_rank"
                class="form-label"
                style={{ color: "#2D3748", fontSize: 15 }}
              >
                确认密码
              </label>
              <InputGroup>
                <Input
                  className={
                    errorMSG.status ? "passwordboxerror" : "passwordbox"
                  }
                  name="amount"
                  type={pweye ? "password" : "text"}
                  id="pw"
                  value={confirmpassword}
                  onChange={(password) => {
                    setCNPassword(password.target.value);
                    password.target.value == newpassword
                      ? seterrorMSG({ status: false, message: " " })
                      : seterrorMSG({
                          status: true,
                          message: "Passwords do not match",
                        });
                  }}
                />

                <InputGroupText
                  className={errorMSG.status ? "inputiconerror" : "inputicon"}
                  onClick={() => {
                    setPweye(!pweye);
                  }}
                >
                  {pweye ? (
                    <AiFillEyeInvisible color="#4F6B75" size={24} />
                  ) : (
                    <AiFillEye color="#4F6B75" size={24} />
                  )}
                </InputGroupText>
              </InputGroup>
              {errorMSG.status ? (
                <p className="errorbox">{errorMSG.message}</p>
              ) : null}
            </div>
            <div style={{ marginTop: 5, padding: 10 }}>
              <label
                for="downline_rank"
                class="form-label"
                style={{ color: "#2D3748", fontSize: 15 }}
              >
                输入 2FA
              </label>
              <InputGroup>
                <Input
                  className={"passwordbox"}
                  name="amount"
                  type={"text"}
                  id="text"
                  value={OTP}
                  onChange={(otp) => {
                    setOTP(otp.target.value);
                  }}
                />
                <InputGroupText
                  className={"inputicon"}
                  onClick={async () => {
                    setOTP(await navigator.clipboard.readText());
                  }}
                >
                  <FaRegPaste color="black" size={22} />
                </InputGroupText>
              </InputGroup>
            </div>
            <div style={{ marginTop: 20, padding: 10, marginBottom: 20 }}>
              <button
                className="btnlogin"
                onClick={(e) => {
                  ResetConfirmPassword(e);
                }}
                style={{ color: "black" }}
              >
                重设密码
              </button>
            </div>
            {processState.state == "..." ? (
              ""
            ) : processState.state == "processing" ? (
              <LoaderCard />
            ) : processState.state == "done" ? (
              <div className="convertsionbox">
                <ConfirmCard tx={processState.data} />
              </div>
            ) : (
              <div className="convertsionbox">
                <ErrorCard err={processState.data} />
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
