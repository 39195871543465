import React, { useState, useEffect } from "react";
import LoaderCard from "../../../LoaderCard/LoaderCard";
import ConfirmCard from "../../../LoaderCard/ConfirmCard";
import ErrorCard from "../../../LoaderCard/ErrorCard";
import dataVal from "../../../../data/Abis.json";
import { invokeservice } from "../../../../api/apiService";
import { FaRegPaste } from "react-icons/fa6";
import {
  Input,
  InputGroup,
  InputGroupText,
  Spinner
} from "reactstrap";
export default function LiquidityRemoveWidget(props) {


  const Web3 = require("web3");
  const web3 = new Web3(process.env.REACT_APP_RPC);
  const EGOLD_ADDRESS = dataVal.egoldtokencontract;
  const BUSD_ADDRESS = dataVal.busdtokencontract;


  const [removeLQ, setremoveLQ] = useState(true);
  const [liquidityTokenBlnce, setliquidityTokenBlnce] = useState(null);
  const [LPTremoveVal, setTLPTremoveVal] = useState(null);
  const [liquidityContract, setliquidityContract] = useState("...");
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });
  const [OTP, setOTP] = useState("");
  const [remapprove, setRemapprove] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("acct")) {
      fetchLQBalance();
    } else {
      setliquidityTokenBlnce(0);
    }
  }, []);

  const fetchLQBalance = async () => {
    if (localStorage.getItem("acct")) {
      const web3 = new Web3(process.env.REACT_APP_RPC);
      const pancakeFactorycontractInstance = await new web3.eth.Contract(
        dataVal.pcfactoryabi,
        process.env.REACT_APP_PANCAKE_FACTORY_ADDR
      );
      await pancakeFactorycontractInstance.methods
        .getPair(
          process.env.REACT_APP_BUSD_ADDR,
          process.env.REACT_APP_EGOLD_ADDR
        )
        .call({}, function (e, res) {
          setliquidityContract(res);
          const LQInstance = new web3.eth.Contract(dataVal.tokenabi, res);
          LQInstance.methods
            .balanceOf(localStorage.getItem("acct"))
            .call({}, function (e, resbalance) {
              setliquidityTokenBlnce(
                noround(parseFloat(web3.utils.fromWei(resbalance)), 4)
              );
            });
        });
    }
  };
  function checkerror(err) {
    if (
      err.message ==
      "Please pass numbers as strings or BN objects to avoid precision errors." || err.message.includes("while converting number to string, invalid number value ")
    ) {
      setprocessState({
        state: "error",
        data: "Please provide a valid input",
      });
    } else if (JSON.stringify(err.message).includes("transaction underpriced"))
      setprocessState({
        state: "error",
        data: "Transaction was underpriced. Please try increasing the gas price",
      });
    else
      setprocessState({
        state: "error",
        data: JSON.stringify(err.message),
      });
  }

  const removeLiquidity = async () => {
    setprocessState({ state: "...", data: null });
    if (localStorage.getItem("isoptionaltfa") == "false" || OTP) {
      if (LPTremoveVal != null) {
        if (parseFloat(LPTremoveVal) <= parseFloat(liquidityTokenBlnce)) {
          var today = new Date();
          var futureDate = new Date(today.getTime() + 60000);
          var LQamnt = String(LPTremoveVal);
          var Zamnt = String(0.0);

          try {
            setprocessState({ state: "processing", data: null });
            const token = localStorage.getItem("accessToken");
            const removeLQ = {
              job: {
                type: "removeLiquidity",
                params: {
                  useragent: navigator.userAgent,
                  lqpairAdd: liquidityContract,
                  balanceLp: LQamnt,
                  tokenA: BUSD_ADDRESS,
                  tokenB: EGOLD_ADDRESS,
                  totp:
                    localStorage.getItem("isoptionaltfa") == "true" ? OTP : "",
                },
              },
            };
            var headers = {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(token),
              },
            };
            invokeservice(removeLQ, headers).then((json) => {
              if (json.data.status != "failed") {
                fetchLQBalance();
                setprocessState({ state: "done", data: json.data.task_id });
              } else {
                setprocessState({
                  state: "error",
                  data: json.data.message,
                });
              }
            });
          } catch (err) {
            console.log(err);
          }
        } else {
          setprocessState({
            state: "error",
            data: "Insufficient Balance",
          });
        }
      } else {
        setprocessState({
          state: "error",
          data: "Invalid Amount",
        });
      }
    }
  };


  const getLPTval = async (e) => {
    setTLPTremoveVal(e);
  };
  const getMaxLPT = () => {
    setTLPTremoveVal(liquidityTokenBlnce);
  };

  //to not round values while showing balance
  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }
  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      var x = noround(num, 2);
      return x; // if value < 1000, nothing to do
    }
  }

  return (
    <div class="detailCard secpadding mb20">
      <div class="fromreactangle p15 mb15">
        <p class="fromreactp">
          <input
            className="swapinput"
            placeholder="0.00"
            value={LPTremoveVal}
            onChange={(e) => getLPTval(e.target.value)}
          />{" "}
          <span
            class="float-right fromreactspan"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "3px",
            }}
          >
            <span className="egoldicon" style={{ height: "26px" }}></span>
            LPT{" "}
          </span>
        </p>
        <p class="fs12">
        平衡: {liquidityTokenBlnce}{" "}
          <span
            class="float-right yellowtext fs14"
            style={{
              cursor: "pointer",
            }}
            onClick={(e) => getMaxLPT()}
          >
            最大使用
          </span>
        </p>
      </div>
      {
        localStorage.getItem("isoptionaltfa") == "true" ?
          <div style={{ marginTop: 20, marginBottom: 30 }}>
            <div style={{ marginTop: 10 }}>
              <label for="receiver_address" class="form-label">
                请输入2FA代码 :
              </label>
              <InputGroup>
                <Input
                  name="otp"
                  type={"text"}
                  id="otp"
                  value={OTP}
                  onChange={(otp) => {
                    setOTP(otp.target.value);
                  }}
                  style={{
                    borderTop: "0.489247px solid rgb(222, 226, 230)",
                  }}
                  className='passwordbox'
                />
                <InputGroupText
                  className={"inputicon"}
                  onClick={async () => {
                    setOTP(await navigator.clipboard.readText());
                  }}
                >
                  <FaRegPaste color='black' size={22} />
                </InputGroupText>
              </InputGroup>
            </div>

          </div> : null
      }
      <button
        type="button"
        className="btn-color-primary"
        style={{ width: "100%", border: "0px" }}
        onClick={(e) => removeLiquidity()}
      // disabled={removeLQ}
      >
        Remove Liquidity
      </button>
      {processState.state == "..." ? (
        ""
      ) : processState.state == "processing" ? (
        <LoaderCard />
      ) : processState.state == "done" ? (
        <ConfirmCard tx={processState.data} />
      ) : (
        <ErrorCard err={processState.data} />
      )}
    </div>
  );
}
