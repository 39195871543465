import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ConfirmCard(props) {
  let navigate = useNavigate();

  return (
    <>
      <div
        class="transictionconfirmsec txcards"
        style={
          props.bg == "dark"
            ? { background: "#e0e7ea", gap: "20px" }
            : { gap: "20px" }
        }
      >
        <p class="text1">我们正在处理您的请求 </p>

        <a
          onClick={() => {
            navigate("/notifications");
          }}
          target="_blank"
          class="btn-outline-grey"
          style={{ background: "white" }}
        >
         查看交易
        </a>
      </div>
    </>
  );
}
