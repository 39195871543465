import React, { useEffect, useState } from "react";
import dataVal from "../../data/Abis.json";
import downarrow from "../../images/downarrow.svg";
import egold from "../../images/currency/egold.svg";
import link from "../../images/link.svg";
import crossicon from "../../images/cross.svg";
import LoaderCard from "../LoaderCard/LoaderCard";
import ConfirmCard from "../LoaderCard/ConfirmCard";
import ErrorCard from "../LoaderCard/ErrorCard";
const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);

export default function StakeUpcoming(props) {
    console.log("propssss===", props);
    const [activeElementId, setActiveElementId] = useState(null);
    const [egoldstakedAmnt, setegoldstaked] = useState("...");
    const [pending, setpending] = useState("...");
    const [endBlk, setendBlk] = useState("...");
    const [startBlk, setstartBlk] = useState("...");
    const [totalEgoldStaked, settotalEgoldStaked] = useState("...");
    const [currentBlk, setcurrentBlk] = useState("...");
    const showData = (elementId) => {
        const isActive = elementId === activeElementId;
        setActiveElementId(isActive ? null : elementId);
    };
    const [btnLoader, setbtnLoader] = useState(false);
    const [processState, setprocessState] = useState({
        state: "...",
        data: null,
    });



    function calculateFMperiod(start, end) {
        var difference = end - start;
        difference = difference * 3;
        let days = difference / 86400;
        console.log("DAYS", days);
        return parseInt(days);
    }


    //to round decimal points with zeroes
    function roundzeroes(val, x) {
        var float = parseFloat(val).toFixed(18);
        var num = float.toString();
        var n = num.slice(0, num.indexOf(".") + (x + 1));
        return n;
    }



    return (
        <div class="stakeliverow">
            <div class="slfsgroup">
                <div class="slfirst">
                    <span class="stakelivebtn"> 年利率: ...% </span>
                    <span class="upcomingbadge"> 即将推出 </span>
                </div>
                <div class="slsecond">
                    <span>
                        {" "}
                        赌注 EGOLD <img src={egold} alt="" style={{ height: "20px" }} />
                    </span>
                    <span class="float-right">
                        {" "}
                        赚: {props.cmsdata.attributes.projectName == "EGOLD" ? props.cmsdata.attributes.projectName : props.itemdata.tokname}{" "}
                        <img src={props.cmsdata.attributes.icon.data.attributes.url} style={{ height: "20px" }} alt="" />
                    </span>
                </div>
            </div>
            <div
                className={`slfourth ${activeElementId ? "active" : ""}`}
                id="fourslidebtn"
            >
                <div class="slfourthbtns">
                    <button
                        class="slfourthbtn"
                        id="detbtn"
                        onClick={() => showData("fourthslidecontent", "detbtn")}
                    >
                        {" "}
                        细节 <img src={downarrow} alt="" />
                    </button>
                    <button
                        class="slfourthbtn"
                        id="infobtn"
                        onClick={() => showData("fourthslidecontent2", "infobtn")}
                    >
                        {" "}
                        {props.cmsdata.attributes.projectName == "EGOLD" ? props.cmsdata.attributes.projectName : props.itemdata.tokname} 信息 <img src={downarrow} alt="" />
                    </button>
                </div>
                {/* <div class="slfourthbtns">
                    <div
                        class="slcollectbtn"
                        id="cltbtn"
                        onClick={() => showData("fourthslidecontent3", "cltbtn")}
                        style={{ cursor: "pointer" }}
                    >
                        {" "}
                        Collect{" "}
                    </div>
                </div> */}
            </div>
            <div
                id="fourthslidecontent"
                className={`slide-down-content ${activeElementId === "fourthslidecontent" ? "active" : ""
                    }`}
            >
                <div class="performancehistroy pb-0 slfourthdel">
                    <div>
                        <p class="minerownep">可用{props.cmsdata.attributes.projectName == "EGOLD" ? props.cmsdata.attributes.projectName : props.itemdata.tokname}</p>
                        <p class="minerownep2">{props.cmsdata.attributes.TokensOffered}</p>
                    </div>
                    <div>
                        <p class="minerownep">EGOLD 质押 </p>
                        <p class="minerownep2">{"..."}</p>
                    </div>
                    <div>
                        <p class="minerownep">年利率 </p>
                        <p class="minerownep2">...%</p>
                    </div>
                </div>
                <div class="performancehistroy slfourthdel">
                    <div>
                        <p class="minerownep">农耕时期</p>
                        <p class="minerownep2">~{props.cmsdata.attributes.startBlock == "..." || props.cmsdata.attributes.endBlock == "..." ? "..." : calculateFMperiod(props.cmsdata.attributes.startBlock, props.cmsdata.attributes.endBlock)} 天</p>
                    </div>
                    <div>
                        <p class="minerownep">启动块</p>
                        <a
                            href={"https://bscscan.com/block/" + props.cmsdata.attributes.startBlock}
                            target="_blank"
                        >
                            <p class="minerownep2">#{props.cmsdata.attributes.startBlock}</p></a>
                    </div>
                    <div>
                        <p class="minerownep">端块</p>
                        <a
                            href={"https://bscscan.com/block/" + props.cmsdata.attributes.endBlock}
                            target="_blank"
                        >
                            <p class="minerownep2 ">#{props.cmsdata.attributes.endBlock}</p></a>
                    </div>
                </div>
            </div>
            <div
                class="slide-down-content"
                id="fourthslidecontent2"
                className={`slide-down-content ${activeElementId === "fourthslidecontent2" ? "active" : ""
                    }`}
            >
                <div class="slidecontent">
                    <p class="slidecontentp">
                        {props.cmsdata.attributes.description}
                    </p>
                    <div class="tokenmorebtsn">
                        <a target="_blank" href={"https://bscscan.com/address/" + props.cmsdata.attributes.tokensAddress} style={{ cursor: "pointer", color: "#253237" }}>
                            <img src={link} alt="" />
                            代币合约{" "}
                        </a>
                        <span>
                            <img src={link} alt="" />
                            更多信息{" "}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
