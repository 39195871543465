import React from "react";
import { useNavigate } from "react-router-dom";
import EgoldLogo from "../../../images/Egold_logo.svg"
import success from "./img/success.png";
import { IoArrowBackCircleOutline, } from "react-icons/io5";

const SuccessFailure = () => {
  let history = useNavigate();
  return (
    <>
      <br />

      <main className='loginsection' >
        <div class="mainsection ">
          <div class="logintabsec">

            <div class="loginsec" >
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10 }}>
                <img src={EgoldLogo} />
              </div>

              <div className="my-5" style={{ textAlign: "center" }}>
                <h3 className=" font-bold text-[16px] mb-5 text-center h2color" style={{ color: "#717070", fontSize: 22 }}>
                电子邮件验证成功完成
                </h3>
                <img height="150px" src={success} />

                <div style={{ justifyContent: "center", display: "flex" }}>
                  <a class="minerAvailablebtn" style={{ marginTop: 20, padding: 8, width: "50%", cursor: "pointer", display: "flex", gap: 10, border: 'unset' }} href="/">
                    <IoArrowBackCircleOutline color="black" size={22} />回到登入</a>
                </div>

                {/* <p className="text-center mt-5" 
                }
                  onClick={() => history("/")}
                >
                  回到登入
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </main>


      {/* <div class="app-content" style={{ padding: "0px" }}>
        <div className="bg-[#181b1d] flex flex-col items-center ">
          <div className="py-4" style={{ textAlign: "center" }}>
            <img alt="Logo" height="45px" src={EgoldLogo} />
          </div>
          <div className="my-5" style={{ textAlign: "center" }}>
            <h3 className="text-[#000] font-bold text-[16px] mb-5 text-center h2color" style={{ color: "black" }}>
              Email Verification Completed Successfully
            </h3>
            <img height="150px" src={success} />
            <p className="text-center mt-5">
              <a
                style={{ color: "black", cursor: "pointer" }}
                onClick={() => {
                  history("/");
                }}
              >
                Click here to Login
              </a>
            </p>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default SuccessFailure;
