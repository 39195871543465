import React, { useState, useEffect } from 'react'
import dataVal from "../../../data/Abis.json";
import { Spinner } from 'reactstrap';
import LoaderCard from "../../LoaderCard/LoaderCard";
import ConfirmCard from "../../LoaderCard/ConfirmCard";
import ErrorCard from "../../LoaderCard/ErrorCard";
import { FaRegPaste } from "react-icons/fa6";
import { invokeservice } from '../../../api/apiService';
import {
    Input,
    InputGroup,
    InputGroupText
} from "reactstrap"
const Web3 = require("web3");

export default function MinerInfoJunkyard(props) {
    const web3 = new Web3(process.env.REACT_APP_RPC);

    const [minerName, setMinerName] = useState("...");
    const [maxClaimCount, setmaxClaimCount] = useState(10);
    const [loaderBTN, setloaderBTN] = useState(false);
    const [processState, setprocessState] = useState({
        state: "...",
        data: null,
    });
    const [OTP, setOTP] = useState("");


    useEffect(() => {
        getMinerInfo();
    }, []);

    console.log("!!props", props)

    const getMinerInfo = async () => {
        const contractInstance = new web3.eth.Contract(
            dataVal.mregistryabi,
            process.env.REACT_APP_MINER_REGISTRY
        );

        await contractInstance.methods
            .fetchMinerInfo(props.type.mType)
            .call()
            .then((res) => {
                setMinerName(res.name);
            });
    };

    async function deleteminer() {
        setloaderBTN(true)
        let fromaddr = localStorage.getItem("acct");
        let toaddr = "0x0000000000000000000000000000000000000000";
        try {
            if (localStorage.getItem("isoptionaltfa") == "false" || OTP) {
                const token = localStorage.getItem("accessToken");
                const user = localStorage.getItem("email");
                const deteteData = {
                    job: {
                        type: "DeleteMiner",
                        params: {
                            useragent: navigator.userAgent,
                            user: user,
                            miner_id: props.type._id,
                            totp: localStorage.getItem("isoptionaltfa") == "true" ? OTP : "",
                        },
                    },
                };
                var headers = {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + String(token),
                    },
                };
                invokeservice(deteteData, headers).then((json) => {
                    setloaderBTN(false)

                    setOTP("")
                    if (json.data.status != "failed") {
                        if (json.data.status != "failed") {
                            setprocessState({ state: "done", data: json.data.task_id });
                        } else {
                            setprocessState({ state: "error", data: json.data.message });
                        }
                    } else {
                        setprocessState({ state: "error", data: json.data.message });
                    }

                });
            }
        } catch (err) {
            setloaderBTN(false)
            console.log(err);
            setprocessState({ state: "error", data: err });
        }
    }

    return (
        <div>
            <div class="mineraccordian">
                <div class="accordion" id="accordionExample">

                    <div class="mineaccordianall">
                        <div class="mineaccorsec">
                            <div class="minerstate">
                                <div class="mineaccorimg">
                                    {
                                        minerName != "..." ?
                                            <img
                                                src={require("../../../images/miners/" + minerName + ".png")}
                                                alt="" class="img-fluid" /> : null
                                    }

                                </div>
                                <span class="expirebadge">Expired</span>
                            </div>
                            <div class="mineaccorbtnsec">
                                <div class="mineaccorbtns mb15">
                                    <a class="mineaccorbtn active">矿工ID: {props.type._id}</a>
                                    <a class="mineaccorbtn">矿工功率 {props.type.mhash} Mhs</a>
                                </div>
                                <div class="mineaccorbtns">
                                    <a class="mineaccorbtn">矿工类型: {minerName}</a>
                                    <a class="mineaccorbtn">已经认领:{" "}

                                        {Number(
                                            props.consumed.ctr[props.type._id]
                                        )}
                                        /{maxClaimCount}</a>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item mineraccordianitem mineaccoritem" style={{ background: "#e0e7ea" }}>
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button collapsed mineraccordianbtn minebtn" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne" + props.index} aria-expanded="true" aria-controls="collapseOne"> Miner Details </button>
                            </h2>
                            <div id={"collapseOne" + props.index} class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body mineraccordianbody">
                                    {
                                        localStorage.getItem("isoptionaltfa") == "true" ?
                                            <div style={{ marginTop: 20, marginBottom: 30 }}>
                                                <div style={{ marginTop: 10, padding: "0px 20px" }}>
                                                    <label for="receiver_address" class="form-label">
                                                        请输入2FA代码 :
                                                    </label>
                                                    <InputGroup>
                                                        <Input
                                                            name="otp"
                                                            type={"text"}
                                                            id="otp"
                                                            value={OTP}
                                                            onChange={(otp) => {
                                                                setOTP(otp.target.value);
                                                            }}
                                                            style={{
                                                                borderTop: "0.489247px solid rgb(222, 226, 230)",
                                                            }}
                                                            className='passwordbox'
                                                        />
                                                        <InputGroupText
                                                            className={"inputicon"}
                                                            onClick={async () => {
                                                                setOTP(await navigator.clipboard.readText());
                                                            }}
                                                        >
                                                            <FaRegPaste color='black' size={22} />
                                                        </InputGroupText>
                                                    </InputGroup>
                                                </div>

                                            </div> : null
                                    }
                                    <div class="selectcurbtnsec appactiveminer"
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) => deleteminer(props.type._id)}
                                    >
                                        <a class="btn-color-primary w-100">
                                            {loaderBTN ? <Spinner color='black' style={{ height: 16, width: 16 }} /> : "发送至垃圾场"}
                                        </a>
                                    </div>
                                    {processState.state == "..." ? (
                                        ""
                                    ) : processState.state == "processing" ? (
                                        <div style={{ margin: "20px" }}>
                                            <LoaderCard />
                                        </div>
                                    ) : processState.state == "done" ? (
                                        <div style={{ margin: "20px" }}>
                                            <ConfirmCard tx={processState.data} />
                                        </div>
                                    ) : (
                                        <div style={{ margin: "20px" }}>
                                            <ErrorCard err={processState.data} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
