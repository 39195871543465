import React, { useState, useEffect, useRef } from 'react';
import MinerInfo from "./MinerInfoJunkyard"
import { Spinner } from "reactstrap";
import PillsMenu from "../../Menu/PillsMenu";
import MinePillsMenu from "../MinePillsMenu"
import dataVal from "../../../data/Abis.json";

const Web3 = require("web3");

export default function Junkyard() {
    const CLAIM_COUNT_ABI = dataVal.ctrAbi;
    const CLAIM_COUNTER_CONTRACT_ADDRESS = process.env.REACT_APP_CLAIM_CTR;
    const NFT_ABI = dataVal.nftAbi;
    const FARMING_ABI = dataVal.farmAbi;
    const NFT_ADDRESS = process.env.REACT_APP_NFT_ADDR;
    const web3 = new Web3(process.env.REACT_APP_RPC);
    const nftInstance = new web3.eth.Contract(NFT_ABI, NFT_ADDRESS);
    const FARMING_ADDRESS = process.env.REACT_APP_FARMING_ADDR;
    const farmInstance = new web3.eth.Contract(FARMING_ABI, FARMING_ADDRESS);


    const [consumedCounter, setconsumedCounter] = useState({
        status: {},
        loading: true,
        ctr: {},
    });
    const [minerOwned, setMinersOwned] = useState("...");
    const [stakedCount, setStakedCount] = useState(0);
    const [totalInactiveMhash, setInactiveMhash] = useState(0);
    const [stakedMhash, setStakedMhash] = useState(0);
    const [consumedHash, setconsumedHash] = useState(0);
    const [totalpending, setTotalpending] = useState(0);
    const [minestatData, setMineStatData] = useState({
        data: null,
        loading: true,
    });

    const [summaryData, setSummaryData] = useState({ data: null, loading: true });
    const [userbnbBalance, setUserbnbbalance] = useState("...");
    let testobj = [];
    let ctrObj = {};

    useEffect(() => {
        getSummary();
    }, []);
    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        // await nftInstance.methods.balanceOf(localStorage.getItem("acct")).call({}, function (e, res) {
        //     console.log("JJJJJ", res)
        //     setMinersOwned(res);
        // });
        const url2 = "https://egold-miner.tagdev.info/miner/" + localStorage.getItem("acct");

        var stakedhash = 0;
        var pending = 0;

        fetch(url2)
            .then(function (response) {
                return response.json();
            })
            .then(function (newData) {
                if (Array.isArray(newData)) {
                    console.log("JJJJJ1!!", newData.length)
                    setStakedCount(newData.length);
                    for (let i = 0; i < newData.length; i++) {
                        nftInstance.methods
                            .fetchMinerInfo(newData[i]._id)
                            .call({}, async function (e, ress) {
                                stakedhash += parseInt(ress[2]);

                                setStakedMhash(parseInt(stakedhash));
                            });
                        farmInstance.methods
                            .pending(0, newData[i]._id)
                            .call({}, async function (e, res) {
                                pending =
                                    parseFloat(pending) + parseFloat(web3.utils.fromWei(res));
                                setTotalpending(pending);
                            });
                    }
                }
            })


        const minerurl =
            "https://egold-treasury.tagdev.info/v1/summary/minersOf/" +
            localStorage.getItem("acct")

        var sum = 0;
        var sumRes = 0;
        const counterContractInstance = await new web3.eth.Contract(
            CLAIM_COUNT_ABI,
            CLAIM_COUNTER_CONTRACT_ADDRESS
        );
        fetch(minerurl)
            .then(function (response) {
                return response.json();
            })
            .then(function (newData) {
                if (Array.isArray(newData)) {
                    setMinersOwned(newData.length)
                    for (let i = 0; i < newData.length; i++) {

                        counterContractInstance.methods
                            .fetchCtr(newData[i]._id)
                            .call(function (err, res) {
                                if (err) {
                                    console.log("An error occured", err);
                                    return;
                                }
                                let currntObj = {};
                                currntObj[newData[i]._id] = res;
                                console.log("counter ress--", res);
                                if (parseInt(res) >= 10) {
                                    nftInstance.methods
                                        .fetchMinerInfo(newData[i]._id)
                                        .call({}, async function (e, ress) {
                                            sumRes += parseInt(ress[2]);
                                            console.log("sum = ", sumRes);
                                            setconsumedHash(parseInt(sumRes));
                                        });
                                    console.log("counter ress--", res);
                                } else {
                                    nftInstance.methods
                                        .fetchMinerInfo(newData[i]._id)
                                        .call({}, async function (e, ress) {
                                            sum += parseInt(ress[2]);
                                            console.log("sumNFT = ", sum);
                                            setInactiveMhash(parseInt(sum));
                                        });
                                }
                            });
                    }
                }
            })
        const minestaturl =
            "https://egold-miner.tagdev.info/mininginfo/" + localStorage.getItem("acct")

        var sum = 0;

        fetch(minestaturl)
            .then(function (response) {
                return response.json();
            })
            .then(function (newData) {
                setMineStatData({ data: newData, loading: false });
            })
            .catch(function (error) {
                console.log("Requestfailed", error);
            });


    }
    const getSummary = async () => {
        const web3 = await new Web3(process.env.REACT_APP_RPC);
        const url =
            "https://egold-treasury.tagdev.info/v1/summary/minersOf/" +
            localStorage.getItem("acct")

        var xbal = await web3.eth.getBalance(localStorage.getItem("acct"));
        setUserbnbbalance(noround(web3.utils.fromWei(xbal), 5));

        fetch(url)
            .then(function (response) {
                return response.json();
            })
            .then(async function (newData) {
                console.log("newdata---", newData);

                setSummaryData({ data: newData, loading: false });
            })
            .catch(function (error) {
                console.log("Requestfailed", error);
            });
    };
    const getCurrentCtr = async (id) => {
        const web3 = await new Web3(process.env.REACT_APP_RPC);
        const counterContractInstance = await new web3.eth.Contract(
            dataVal.ctrAbi,
            process.env.REACT_APP_CLAIM_CTR
        );
        await counterContractInstance.methods
            .fetchCtr(id)
            .call(function (err, res) {
                if (err) {
                    console.log("An error occured", err);
                    return;
                }
                let currntObj = {};
                currntObj[id] = res;

                console.log("counter ress--", res);
                if (parseInt(res) >= 10) {
                    let trueID = summaryData.data.findIndex((x) => x._id === id);
                    ctrObj[id] = res;
                    testobj.push(summaryData.data[trueID]);
                    console.log("expired here", testobj)
                    setconsumedCounter({
                        status: testobj,
                        loading: false,
                        ctr: ctrObj,
                    });
                } else {
                    setconsumedCounter({
                        status: testobj,
                        loading: false,
                        ctr: ctrObj,
                    });
                }
            });
    };

    useEffect(() => {
        if (summaryData.data != null) {
            if (summaryData.data.length == 0) {
                setconsumedCounter({
                    status: testobj,
                    loading: false,
                    ctr: ctrObj,
                });
            }
            summaryData.data.map((element) => {
                getCurrentCtr(element._id);
            });
        }
    }, [summaryData.data]);


    function noround(val, x) {
        var float = parseFloat(val).toFixed(18);
        var num = float.toString();
        var n = num.slice(0, num.indexOf(".") + (x + 1));
        return n;
    }

    function delay(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    return (
        <div
            class="mainsection"
            style={{
                position: "relative",
            }}
        >
            <div class="minertabsec secpadding lightgrey-bg brfull">
                <PillsMenu activeval="mine" />
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-trade" role="tabpanel" aria-labelledby="pills-trade-tab" tabindex="0">
                        <div class="wallettabsec egoldsubtabsec lightgrey-bg brtlr">

                            <div class="walletamtsec mb20">
                                <div class="minerownedsec">
                                    <div class="row">
                                        <div class="col-6 d-flex align-items-center">
                                            <p class="minersownedp">Miners owned</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="minersownedp text-end">{minerOwned != "..." ? parseInt(minerOwned) + stakedCount : "..."}/25 ( {totalInactiveMhash + stakedMhash + consumedHash} Mhs)</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="minerownedpsec">
                                        <div>
                                            <p class="minerownep">已挖矿（EGOLD）</p>
                                            <p class="minerownep2">
                                                {minestatData.loading === true
                                                    ? "...."
                                                    : minestatData.data.egold_mined
                                                        ? parseFloat(minestatData.data.egold_mined + totalpending).toFixed(5)
                                                        : parseFloat(0).toFixed(5)}

                                            </p>
                                        </div>
                                        <div>
                                            <p class="minerownep">已收集（Egold）</p>
                                            <p class="minerownep2">
                                                {minestatData.loading === true
                                                    ? "...."
                                                    : minestatData.data.egold_mined
                                                        ? parseFloat(minestatData.data.egold_mined).toFixed(5)
                                                        : parseFloat(0).toFixed(5)}</p>
                                        </div>
                                        <div>
                                            <p class="minerownep">待定 (Egold)</p>
                                            <p class="minerownep2">  {parseFloat(totalpending).toFixed(5)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <MinePillsMenu activeval="junk" inactivecount={minerOwned} activecount={stakedCount} />
                            {(summaryData.loading || consumedCounter.loading) == true ? (
                                <>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <Spinner color="black" size={22} />
                                    </div>
                                </>
                            ) : (
                                <>
                                    {console.log("expired length", Object.keys(consumedCounter.status).length)}
                                    {Object.keys(consumedCounter.status).length == 0 ? (
                                        <span
                                            style={{
                                                backgroundColor: "rgb(79 107 117)",
                                                textAlign: "center",
                                                padding: "10px",
                                                borderRadius: "10px",
                                                margin: "2px 20px",
                                                display: "block",
                                                fontSize: "14px",
                                                color: "white",
                                                textTransform: "uppercase",
                                            }}
                                        >
                                            垃圾场没有矿工
                                        </span>
                                    ) : (
                                        consumedCounter.status.map(function (element, i) {
                                            return (<MinerInfo type={element} index={i} consumed={consumedCounter} />)


                                        })
                                    )}

                                </>
                            )
                            }
                        </div >
                    </div >
                </div >
            </div >

        </div >
    )
}
